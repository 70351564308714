<!-- 用户信息页面（新增、编辑、详情） -->
<template>
  <div>
    <a-modal
      :title="
        isCreate == 0
          ? '新增用户'
          : isCreate == 1
          ? '编辑用户'
          : isCreate == 2
          ? '新用户注册'
          : '用户详情'
      "
      width="60%"
      okText="确定"
      cancelText="取消"
      v-model:visible.sync="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form ref="userForm" :model="userInfo" :rules="rules">
          <a-row>
            <a-col :span="11">
              <a-form-item
                label="用户名:"
                name="realname"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-input
                  v-model:value="userInfo.realname"
                  type="text"
                  :readonly="isCreate == 3 ? true : false"
                  placeholder="请输入用户名"
                  ><template v-slot:prefix>
                    <EditOutlined
                      style="color: rgba(0, 0, 0, 0.25)"
                    /> </template
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item
                label="工号:"
                name="code"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-input
                  v-model:value="userInfo.code"
                  type="text"
                  :readonly="isCreate == 3 ? true : false"
                  placeholder="请输入工号"
                  ><template v-slot:prefix>
                    <EditOutlined
                      style="color: rgba(0, 0, 0, 0.25)"
                    /> </template
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-item
                label="账号:"
                name="username"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-input
                  v-model:value="userInfo.username"
                  type="text"
                  :readonly="isCreate == 3 ? true : false"
                  placeholder="请输入登录账号"
                  ><template v-slot:prefix>
                    <EditOutlined
                      style="color: rgba(0, 0, 0, 0.25)"
                    /> </template
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item
                label="昵称:"
                name="nickname"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-input
                  v-model:value="userInfo.nickname"
                  type="text"
                  :readonly="isCreate == 3 ? true : false"
                  placeholder="请输入昵称"
                  ><template v-slot:prefix>
                    <EditOutlined
                      style="color: rgba(0, 0, 0, 0.25)"
                    /> </template
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-item
                label="单位:"
                name="unit"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-input
                  v-model:value="userInfo.unit"
                  type="text"
                  :readonly="isCreate == 3 ? true : false"
                  placeholder="请输入单位"
                  ><template v-slot:prefix>
                    <EditOutlined
                      style="color: rgba(0, 0, 0, 0.25)"
                    /> </template
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item
                label="行业:"
                name="profession"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-input
                  v-model:value="userInfo.profession"
                  type="text"
                  :readonly="isCreate == 3 ? true : false"
                  placeholder="请输入行业"
                  ><template v-slot:prefix>
                    <EditOutlined
                      style="color: rgba(0, 0, 0, 0.25)"
                    /> </template
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-item
                label="部门:"
                name="depart"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-input
                  v-model:value="userInfo.depart"
                  type="text"
                  :readonly="isCreate == 3 ? true : false"
                  placeholder="请输入部门"
                  ><template v-slot:prefix>
                    <EditOutlined
                      style="color: rgba(0, 0, 0, 0.25)"
                    /> </template
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item
                label="手机:"
                name="phone"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-input
                  v-model:value="userInfo.phone"
                  type="text"
                  :readonly="isCreate == 3 ? true : false"
                  placeholder="请输入手机"
                  ><template v-slot:prefix>
                    <EditOutlined
                      style="color: rgba(0, 0, 0, 0.25)"
                    /> </template
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="22">
              <a-form-item
                label="备注:"
                name="memo"
                :labelCol="{ span: 3 }"
                :wrapperCol="{ span: 21 }"
              >
                <a-textarea
                  :readonly="isCreate == 3 ? true : false"
                  v-model:value="userInfo.memo"
                  placeholder="填写备注说明"
                  :rows="4"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <template #closeIcon
        ><SvgIcon iconName="sclose" className="svg201"
      /></template>
      <template #footer>
        <a-button v-show="isCreate < 3" @click="handleCancel">取消</a-button>
        <a-button v-show="isCreate < 3" type="primary" @click="handleOk"
          >确定</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script lang='js'>
import { EditOutlined } from "@ant-design/icons-vue";
import {
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  defineComponent,
  watch,
} from "vue";
import { message } from "ant-design-vue";
import { saveUser,getCurrentUser } from "@/api/user";
import { useStore } from "vuex";
import SvgIcon from "@/components/Svgicon";
export default defineComponent({
  name: "UserForm",
  components: {
    EditOutlined,
    SvgIcon,
  },
  // 接受父组件传递的值
  props:{
    modalVisible:{
      type: Boolean,
      default: false,
    },
    isCreate:{
      type:Number,
      default: 0,
    },
    searchParam:{
      type:Object,
      default: () => {},
    },
    editData:{
      type:Object,
      default: () => {},
    },
  },  
  setup(props, context) {
    const store = useStore();
    const userForm = ref();
    const state = reactive({ 
      visible: false,
      confirmLoading: false,
      userInfo:{
        id:"",
        realname:"",
        code:"",
        username:"",
        nickname:"",
        phone:"",
        unit:"",
        profession:"",
        depart:"",
        memo:"",
      },      
      formLayout2X2:{
        labelCol: {
              span: 6,
            },
            wrapperCol: {
              span: 18,
            },
      }
    });
    const rules = {
      username: [
        { required: true, message:"账号不能为空", trigger: "blur" },
      ],
      realname: [
        { required: true, message: "用户名不能为空！", trigger: "blur" },
      ],
    };
    onBeforeMount(() => { });
    onMounted(() => {});
    const handleOk = () => {
      userForm.value
        .validate()
        .then((success) => {
          if (success) {
            state.confirmLoading = true;
            saveUser({ui:state.userInfo,mode:props.isCreate}).then((resp) => {
              if (resp) {
                userForm.value.resetFields();
                if (props.isCreate < 2){
                  //获取用户信息
                  getCurrentUser().then(data => {
                    if (data) {
                      let userinfo = data
                      sessionStorage.removeItem("userinfo")
                      sessionStorage.setItem('userinfo', JSON.stringify(userinfo))
                      store.commit('SET_USERINFO', userinfo)                      
                    }
                  });
                }
                setTimeout(() => {
                      context.emit("saveCallBack",resp.code)
                      context.emit("handleModalVisible", false);
                      state.confirmLoading = false;
                    }, 1000);
              }
            });
          } else {
            message.error("用户添加失败！！！");
            return false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });      
    };
    const handleCancel = () => {
      context.emit("handleModalVisible", false);
    };
    const refData = toRefs(state);
    watch(
      () => [props.editData,props.modalVisible],
      ([nv1,nv2]) => {
        state.visible = nv2;
        state.userInfo = nv1;
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...refData,
      rules,
      handleOk,
      handleCancel,
      userForm,
    };
  },
});
</script>
<style lang='scss' scoped>
</style>